import I18n from 'i18n';

$(document)
  .on('unit_member.update.fm', (e, data) => {
    $(`[uid="${data.id}"] [slot="info"]`).text(I18n.t(`group_member.role.${data.params.role_name.toLowerCase()}.name`));
  })
  .on('ajax:beforeSend', '.js-remove-member-from-unit', function ajaxBeforeSendRemoveMember() {
    $(this).closest('bu-member').hide(400);
  })
  .on('ajax:success', '.js-remove-member-from-unit', function ajaxSuccessSendRemoveMember() {
    $(this).closest('bu-member').promise().done(() => {
      const filterLink = document.querySelector('.filter-bar a');
      const match = filterLink.textContent.match(/\d+/);
      const currentCount = parseInt(match, 10) - 1;

      filterLink.textContent = filterLink.textContent.replace(/\d+/, currentCount);
    });
  });
