import Turbolinks from 'turbolinks';

function render(content) {
  return ['<div class="modal form-modal" tabindex="-1" role="dialog">',
    '<div class="modal-dialog" role="document">',
    '<div class="modal-content">',
    content,
    '</div>',
    '</div>',
    '</div>'].join('');
}

function trigger(action, ns, html, params = {}) {
  const eventName = [ns, action, 'fm'].join('.');
  const data = { modal: action, html };
  params || (params = {}); // eslint-disable-line

  if (typeof params === 'object') {
    data.params = params;
    if ('id' in params) { data.id = params.id; }
  } else {
    data.id = params;
  }

  $(document).trigger(eventName, data);
}

function setModalHeight() {
  setTimeout(() => { // modal: app/views/public/phones/_benefits.html.erb problem with calculating the height
    if ($(window).width() < 768) {
      const windowHeight = $(window).outerHeight();
      const headerHeight = $('.form-modal .modal-header').length > 0 ? $('.form-modal .modal-header').outerHeight() : 0;
      const footerHeight = $('.form-modal .modal-footer').length > 0 ? $('.form-modal .modal-footer').outerHeight() : 0;
      $('.modal-body').css('min-height', windowHeight - headerHeight - footerHeight);
    } else {
      $('.modal-body').css('min-height', 'auto');
    }
  }, 0);
}

App.Modal = function Modal(ns, html, params) {
  const $html = $(html);
  const data = $html.data();
  $html.attr('data-role', null);

  const $modalContent = $('.form-modal .modal-content');

  if ($modalContent.length) {
    $modalContent.html($html);
    setModalHeight();
  } else {
    $(render(html)).appendTo(document.body).modal({
      backdrop: 'static',
    });
  }

  const $formModal = $('.form-modal');
  if (data.role) {
    $formModal.attr('data-role', data.role);
  } else {
    $formModal.attr('data-role', null);
  }

  trigger('show', ns, html, params);
  trigger('any', ns, html, params);
};

App.Modal.create = function create(ns, html, params) {
  trigger('create', ns, html, params);
  trigger('any', ns, html, params);
  $('.form-modal').modal('hide');
};

App.Modal.update = function update(ns, html, params) {
  trigger('update', ns, html, params);
  trigger('any', ns, html, params);
  $('.form-modal').modal('hide');
};

App.Modal.destroy = function destroy(ns, html, params) {
  trigger('destroy', ns, html, params);
  trigger('any', ns, html, params);
  $('.form-modal').modal('hide');
};

App.Modal.refresh = function refresh(ns, html, params) {
  trigger('refresh', ns, html, params);
  trigger('any', ns, html, params);
};

$(document)
  .on('hide.bs.modal', '.form-modal', (e) => {
    const data = App.modalData || {};
    if (data.turbolinks_url) {
      e.preventDefault();
      Turbolinks.visit(data.turbolinks_url);
    } else if (data.url) {
      e.preventDefault();
      jQuery.getScript(data.url);
    }

    App.modalData = null;

    // Hide all active popovers
    document.querySelectorAll('.popover').forEach((p) => {
      $(p).popover('hide');
    });
  })
  .on('hidden.bs.modal', '.form-modal', function onHiddenFormModal(e) {
    if (this === e.target) { $(this).remove(); }
  })
  .on('shown.bs.modal', '.form-modal', function onShownFormModal() {
    setModalHeight();
    $(this).find('form').enableClientSideValidations().find('[autofocus]')
      .focus();
    $(this).find('.nested-fields').parent().trigger('cocoon:after-insert');
  })
  .on('click', '[data-modal-content]', (e) => {
    e.preventDefault();

    const content = $(e.currentTarget.getAttribute('data-modal-content')).html();
    content !== undefined && App.Modal('customModal', content);
  });
