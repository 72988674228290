/* global workerFullStop */
import '@beunity/design/assets/stylesheets/application.scss';
import '../assets/stylesheets/public_extend.css';

import 'src/critical';

// Normal
import Turbolinks from 'turbolinks';
import I18n from 'i18n';
import 'src/patches/turbolinks_render_error_pages';

// Vendor libraries
import 'src/vendor/cocoon';
import 'iframe-resizer/js/iframeResizer.contentWindow';

import 'src/newpusher'; // We need to export pusher before importing web_components

// Initializers
import 'src/initializers';

// Web components
import '@beunity/design';
import 'src/web_components';

// Test mode helper
import 'src/test_mode';

// Application
import 'src/file_upload';
import 'src/lib/turbolinks_remote_form';
import 'src/lib/turbolinks_handle_get_form';

import 'bootstrap-sass/assets/javascripts/bootstrap.js';

import Snack from '@effectivastudio/snackbar';
import 'src/live_data';
import 'src/new_image_zoomer';
import 'src/filter_form';
import 'src/main_nav_auto_hide';
import 'src/menu_swipe';
import 'src/page_offset_calc';
import 'src/proxy_menu';
import 'src/inline_form';

import 'src/mutation_observer';
import 'src/invitations';
import 'src/phones';
import 'src/profile';
import 'src/groups';
import 'src/group_members';
import 'src/group_settings';
import 'src/notifications';
import 'src/comments';
import 'src/cards';
import 'src/form_modal';

import 'src/quick_action_menu';
import 'src/smart_date_picker';

import 'src/toggle_visibility';
import 'src/unique_option';
import 'src/slider';
import 'src/submenu';
import 'src/tooltips';
import 'src/new_mobile_demo';
import 'src/community_trial_screen';
import 'src/attachments';
import 'src/auto_save_form';
import 'src/photo_collage';
import 'src/download_file';
import 'src/i18n_xray';
import 'src/update_app_modal';
import 'src/smart_select';
import 'src/scroll_to';
import 'src/select_custom_label';
import 'src/viewport_height';
import 'src/chromium_fix';
import 'src/maximize_element';
import 'src/block_clone';
import 'src/advance_members';
import 'src/full_calendar';

import 'src/turbolinks_visit_destination';
import 'src/new_chat';

// Mobile + Desktop
import 'src/new_common_interface';

import { requirePhoneConfirmation, renderPhoneConfirmationModal } from 'src/lib/require_phone_confirmation';

// wip - Work in progress
// This code should be moved to beUnity design project

window.App ||= {};
window.App.utils ||= {};

App.utils.requirePhoneConfirmation = requirePhoneConfirmation;
App.utils.renderPhoneConfirmationModal = renderPhoneConfirmationModal;

// Common content *******************************************************

App.promises || (App.promises = {});

App.promises.turbolinks_load = new Promise(((resolve) => {
  document.addEventListener('turbolinks:load', (e) => {
    resolve(e);
  });
}));

App.promises.window_load = new Promise(((resolve) => {
  window.addEventListener('load', (e) => {
    resolve(e);
  });
}));

App.promises.document_load = new Promise(((resolve) => {
  document.addEventListener('DOMContentLoaded', (e) => {
    resolve(e);
  });
}));

// Application content  ***********************************************************************

document.addEventListener('turbolinks:before-cache', () => {
  $('[data-edit]').trigger('cleanup.turbolinks');
  $.fn.select2 && $('.js-select2.select2-hidden-accessible').select2('destroy');
  $('.modal, .modal-backdrop').remove();
  $('.proxy-menu').remove();
  $('[data-toggle="dropdown"][aria-expanded="true"]').dropdown('toggle');
  $('.snackbar-container').remove();
  document.body.classList.remove('user-dropdown-open');
  App.dropDownMenuOpen = false;
});

/* Toggle mobile menu */
$(document).on('click', '.js-user-menu-dropdown .dropdown-toggle', (e) => {
  e.preventDefault();
  if (document.body.classList.contains('user-dropdown-open')) {
    $('body').removeClass('user-dropdown-open');
    $('body').trigger('scrollPosition:restore');
  } else {
    $('body').trigger('scrollPosition:save');
    $('body').addClass('user-dropdown-open');
  }
});

$(document).on('click', '.user-dropdown-open', (e) => {
  if (e.target.closest('a')) return;
  $('body').removeClass('user-dropdown-open');
  $('body').trigger('scrollPosition:restore');
});
/* Toggle mobile menu */

/* Prevent menu to be visible when using iOS Safari back gesture (swiping from left to right) */
/* Only for domain links (a:not([target="_blank"])) */
let userMenuTouchStart;
const menuItem = '.js-user-menu-dropdown .dropdown-menu a:not([target="_blank"])';

$(document).on('touchstart', menuItem, () => {
  userMenuTouchStart = true;
});

$(document).on('touchmove', menuItem, () => {
  userMenuTouchStart = false;
});

$(document).on('touchend', menuItem, () => {
  userMenuTouchStart && $('body').addClass('user-dropdown-unloaded');
});

document.addEventListener('turbolinks:load', () => {
  $('body').removeClass('user-dropdown-unloaded');
  document.body.classList.remove('swipe-menu-active');
});
/* Prevent menu to be visible when using iOS Safari back gesture (swiping from left to right) */

/* Bootstrap dropdown menu auto dropup according to screen position */
$(document).on('shown.bs.dropdown', '.dropdown', (e) => {
  const { target } = e;
  const $ul = $(target).children('.dropdown-menu');
  const $button = $(target);
  const ulOffset = $ul.offset();
  // how much space would be left on the top if the dropdown opened that direction
  const spaceUp = (ulOffset.top - $button.height() - $ul.height()) - $(window).scrollTop();
  // how much space is left at the bottom
  const spaceDown = $(window).scrollTop() + $(window).height() - (ulOffset.top + $ul.height());
  // switch to dropup only if there is no space at the bottom AND there is space at the top, or there isn't either but it would be still better fit
  if (spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown)) {
    // $(target).removeClass('dropdown');
    $(target).attr('data-dropup-set', true);
    $(target).addClass('dropup');
    $(target).removeClass('dropdown');
  }
}).on('hidden.bs.dropdown', '[data-dropup-set]', (e) => {
  const { target } = e;
  $(target).removeAttr('data-dropup-set', true);
  $(target).removeClass('dropup');
  $(target).addClass('dropdown');
});
/* Bootstrap dropdown menu auto dropup according to screen position */

$(document).on('submit', '.js-turboform', function onSubmitJsTurboform(e) {
  e.preventDefault();
  Turbolinks.visit(this.action + (this.action.indexOf('?') === -1 ? '?' : '&') + $(this).serialize());
});

$(document).on('submit', '.js-turboform', function onSubmitJsTurboform(e) {
  e.preventDefault();
  Turbolinks.visit(this.action + (this.action.indexOf('?') === -1 ? '?' : '&') + $(this).serialize());
});

$(document).on('change', '.js-change-submit', function onChangeJsChangeSubmit() {
  if (this.dataset.remote) {
    $('[type="submit"]', this).click();
  } else {
    $(this).trigger('submit');
  }
});

/** * DISABLE IOS 10+ ZOOM ** */
document.addEventListener('gesturestart', (e) => {
  e.preventDefault();
});

$(document)
  .on('click', '.js-collapse-select .radio label', function onClickCollapseSelect() {
    const $this = $(this);
    const parentID = $this.closest('.collapse').attr('id');

    $(`[aria-controls="${parentID}"]`).text($this.text());
    $(`#${parentID}`).collapse('hide');
  });

// Add body padding for pages where we have btn-create in bottom right corner.
document.addEventListener('turbolinks:load', () => {
  const el = $('.js-btn-create');
  el.length > 0 && $('body').addClass('has-btn-create');
});

// Onboarding steps
$(document)
  .on('ajax:send', '.js-notification-update', (e) => {
    $(e.currentTarget).trigger('step:completed');
  })
  .on('ajax:error', '.js-notification-update', (e) => {
    $(e.currentTarget).trigger('step:uncomplete');
  })
  .on('step:completed', '.step', (e) => {
    $(e.currentTarget).addClass('is-completed');
    $('body').removeClass('nav-up');
  })
  .on('step:uncomplete', '.step', (e) => {
    $(e.currentTarget).removeClass('is-completed');
  });

$(document).on('show.bs.dropdown hidden.bs.dropdown', (e) => {
  App.dropDownMenuOpen = e.type === 'show';
});

$(document)
  .on('ajax:success', '.js-logout', () => {
    workerFullStop();
    Turbolinks.visit('/login', { action: 'replace' });
  });

$(document).on('ajax:success', '.js-snack-saved', () => {
  Snack({ text: I18n.t('snack.saved') });
});

$(document).on('ajax:success', '.js-sent-activation-instructions', () => {
  Snack({ text: I18n.t('snack.sent_activation_instructions') });
});

// Other -------------------------------------------
$(document)
  .on('click', '.js-focus-related', (e) => {
    $(`[data-target="${e.currentTarget.id}"]`).focus();
  })
  .on('click', '.js-select-related', (e) => {
    $(`#${e.currentTarget.dataset.target}`).prop('checked', true);
  });

document.addEventListener('turbolinks:load', () => {
  // Add body class if non-touch device
  // eslint-disable-next-line
  const touchSupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  !touchSupport && $('html').addClass('non-touch');
});

// Set data-page attribute with controller-action variables
document.addEventListener('turbolinks:before-render', ({ data }) => {
  const newBody = data && data.newBody;
  if (!newBody) { return; }

  document.documentElement.setAttribute('data-page', newBody.dataset.htmlPage);
});

$(document).on('send_via_email.create.fm', () => {
  Snack({ text: I18n.t('card.send_via_email.sent') });
});

// Show snack message from local storage
document.addEventListener('turbolinks:load', () => {
  const { text, customClass = 'snackbar--success' } = JSON.parse(localStorage.getItem('beunity_snack_store')) || {};

  if (text) {
    Snack({ text, customClass });
    localStorage.removeItem('beunity_snack_store');
  }
});
